import { memo, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { A11y, Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import {
  Box,
  Flex,
  Heading,
  IconButton,
  IconButtonProps,
  Stack,
  StackProps,
  ThemeTypings,
  useBreakpointValue,
  useToken,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, ArrowRightSmallIcon } from '@udacity/chakra-uds-icons';
import type { BreakpointValue } from '@udacity/chakra-uds-theme';

import { ContentContainer } from './container';
import { Link } from './link';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

export type SlidesPerViewValue = number | 'auto';

export type CarouselProps = {
  items: ReactNode[];
  title?: string;
  titleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
  titleSize?: ThemeTypings['components']['Heading']['sizes'];
  subtitle?: string;
  slidesPerView?: SlidesPerViewValue | BreakpointValue<SlidesPerViewValue>;
  linkText?: string;
  linkHref?: string;
  theme?: 'light' | 'dark';
  buttonContext?: string;
  onNextClick?: () => void;
  onPrevClick?: () => void;
  scrollBarColorOverride?: string;
  navButtonProps?: Partial<IconButtonProps>;
  titleSlot?: ReactNode;
  containerProps?: StackProps;
};

export const Carousel = memo(function Carousel({
  items,
  title,
  titleAs = 'h2',
  titleSize = 'h2',
  slidesPerView = 'auto',
  linkText,
  linkHref,
  theme,
  buttonContext,
  navButtonProps,
  scrollBarColorOverride,
  onNextClick,
  onPrevClick,
  titleSlot = null,
  containerProps,
}: CarouselProps) {
  const [seafoam500, blue500] = useToken('colors', ['accent.sea-foam.500', 'blue.500']);
  const { i18n } = useTranslation();
  const slidesPerViewBreakPointValues = typeof slidesPerView === 'object' ? slidesPerView : { base: slidesPerView };
  const slidesPerViewValue = useBreakpointValue(slidesPerViewBreakPointValues, { ssr: true });
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const languageDir = i18n.dir();

  const navButtonColorScheme = theme === 'dark' ? 'sea-foam' : 'blue';
  const titleColor = theme === 'dark' ? 'white' : 'black';
  const scrollBarColor = theme === 'dark' ? seafoam500 : blue500;

  return (
    <Stack alignItems='center' position='relative' spacing={4} zIndex='base' {...containerProps}>
      <ContentContainer
        display={{ base: 'none', xl: 'block' }}
        paddingEnd={0}
        paddingStart={0}
        sx={{
          '.uds-carousel .swiper-scrollbar-drag': {
            backgroundColor: scrollBarColorOverride || scrollBarColor,
          },
          '.uds-carousel': {
            paddingTop: `var(--carousel-heading-offset-top, 69px)`,
          },
        }}
      >
        <Swiper
          key={`${slidesPerViewValue}`}
          className={`uds-carousel uds-carousel--${languageDir} uds-carousel--contained`}
          modules={[Navigation, A11y, Scrollbar]}
          mousewheel={true}
          scrollbar={{ draggable: true }}
          slidesPerView={slidesPerViewValue ?? 'auto'}
          spaceBetween={16}
          threshold={20}
          onReachBeginning={() => setIsBeginning(true)}
          onReachEnd={() => setIsEnd(true)}
          onFromEdge={() => {
            setIsBeginning(false);
            setIsEnd(false);
          }}
        >
          {items.map((item, i) => (
            <SwiperSlide key={i} style={{ height: 'auto', display: 'var(--carousel-slide-display, block)' }}>
              {item}
            </SwiperSlide>
          ))}
          <Flex alignItems='center' justifyContent='space-between' pos='absolute' top={0} width='100%'>
            {titleSlot
              ? titleSlot
              : title && (
                  <Flex flexDir='column' gap={2}>
                    {title && (
                      <Heading as={titleAs} color={titleColor} size={titleSize}>
                        {title}
                      </Heading>
                    )}
                  </Flex>
                )}
            <Flex className='swiper-button-container' display={{ base: 'none', md: 'flex' }} gap='16px'>
              <NavButton
                buttonContext={buttonContext}
                colorScheme={navButtonColorScheme}
                disabled={isBeginning}
                iconButtonProps={navButtonProps}
                isNext={false}
                languageDir={languageDir}
                onClick={onPrevClick}
              />
              <NavButton
                buttonContext={buttonContext}
                colorScheme={navButtonColorScheme}
                disabled={isEnd}
                iconButtonProps={navButtonProps}
                isNext={true}
                languageDir={languageDir}
                onClick={onNextClick}
              />
            </Flex>
          </Flex>
        </Swiper>

        {linkHref && linkText && (
          <>
            <Box h='24px' />
            <Link href={linkHref} width='fit-content' withArrow>
              {linkText}
            </Link>
          </>
        )}
      </ContentContainer>

      <ContentContainer display={{ base: 'block', xl: 'none' }} paddingEnd={0} paddingStart={0}>
        {titleSlot
          ? titleSlot
          : title && (
              <Flex
                flexDir='column'
                gap={2}
                mb='16px'
                paddingEnd='16px'
                paddingStart='var(--carousel-offset-start, 16px)'
              >
                {title && (
                  <Heading as={titleAs} color={titleColor} size={titleSize}>
                    {title}
                  </Heading>
                )}
              </Flex>
            )}
        <Flex
          gap='16px'
          overflowX='auto'
          overflowY='hidden'
          paddingEnd='16px'
          paddingStart='var(--carousel-offset-start, 16px)'
          width='100%'
        >
          {items}
        </Flex>

        {linkHref && linkText && (
          <>
            <Box h='16px' />
            <Link _visited={{ color: 'blue.500' }} href={linkHref} paddingX='16px' width='fit-content'>
              {linkText} <ArrowRightSmallIcon color='inherit' h='32px' w='32px' />
            </Link>
          </>
        )}
      </ContentContainer>
    </Stack>
  );
});

const NavButton = memo(function NavButton({
  isNext,
  disabled,
  languageDir,
  colorScheme,
  buttonContext,
  onClick,
  iconButtonProps,
}: {
  isNext: boolean;
  disabled?: boolean;
  languageDir: 'ltr' | 'rtl';
  colorScheme?: string;
  buttonContext?: string;
  onClick?: () => void;
  iconButtonProps?: Partial<IconButtonProps>;
}) {
  const swiper = useSwiper();

  const localeIcon = useMemo(() => {
    if (languageDir === 'ltr') {
      return isNext ? <ArrowRightIcon h={6} w={6} /> : <ArrowLeftIcon h={6} w={6} />;
    } else {
      return isNext ? <ArrowLeftIcon h={6} w={6} /> : <ArrowRightIcon h={6} w={6} />;
    }
  }, [isNext, languageDir]);

  return (
    <IconButton
      colorScheme={colorScheme}
      icon={localeIcon}
      isDisabled={disabled}
      p={2}
      size='icon'
      variant='outline'
      aria-label={
        isNext
          ? `Next ${buttonContext ? `set of ${buttonContext}` : ''}`
          : `Previous ${buttonContext ? `set of ${buttonContext}` : ''}`
      }
      isRound
      onClick={() => {
        onClick?.();
        isNext ? swiper.slideNext() : swiper.slidePrev();
      }}
      {...iconButtonProps}
    />
  );
});
